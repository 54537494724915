<template>
  <b-button type="is-social" v-bind="$attrs" v-on="$listeners">
    <img :src="logo" />
  </b-button>
</template>

<script>
import FacebookLogo from '@/assets/social/facebook.png'
export default {
    data () {
        return {
            logo: FacebookLogo
        }
    }
}
</script>
<style>

</style>
